import './App.css';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/header';
import SalesPackage from './components/SalesPackage';
import TrainerSlider from './components/TrainerSlider';
import VideoComponent from './components/VideoComponent';

function App() {
  return (
    <div className="App">
      <Header />
      <VideoComponent />
      <section id="about">
        <AboutUs />
      </section>
      <section id="trainers">
        <TrainerSlider />
      </section>
      <section id="signup">
        <SalesPackage />
      </section>
      <section id="contact">
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
}

export default App;
