import React, { useState } from "react";
import "./ContactUs.css"; // Import your custom styles
import emailjs from "emailjs-com"; // Import EmailJS

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.email && formData.message) {
      // Send the email using EmailJS
      emailjs
        .send(
          "service_9q4hbbq", // Replace with your EmailJS service ID
          "template_ji3166q", // Replace with your EmailJS template ID
          {
            from_name: formData.name,
            reply_to: formData.email,
            message: formData.message,
            to_email: "southernstrengthfl@gmail.com", // Send to this Gmail address
          },
          "P1yVz5aQuAry_i7eo" // Replace with your EmailJS public key
        )
        .then(
          () => {
            setStatus("Message sent successfully!");
            alert("Message sent successfully!");
            setFormData({ name: "", email: "", message: "" }); // Reset the form
          },
          (error) => {
            console.error("Failed to send message:", error);
            alert("Error sending message, try again",error);
            setStatus("Failed to send message. Please try again later.");
          }
        );
    } else {
      setStatus("Please fill out all fields.");
    }
  };

  return (
    <section className="contact-us-section">
      <div className="container mx-auto py-10 px-4 lg:px-0">
        <h2 className="section-title">Contact Us</h2>
        <p className="section-subtitle">
          We would love to hear from you! Please fill out the form below and we
          will get in touch with you shortly.
        </p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="form-input"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              className="form-textarea"
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">
            Send Message
          </button>
        </form>
        {status && <p className="status-message">{status}</p>}
      </div>
    </section>
  );
};

export default ContactUs;
