import React from "react";
import { Link } from 'react-scroll'; // Import Link from react-scroll

const Header = () => {
  return (
    <header className="bg-black shadow-md w-full z-10 pt-12 text-xs lg:text-sm">
      <div className="container mx-auto flex flex-col items-center justify-between">
        {/* Logo */}
        <img
          className="w-72 md:w-96 mb-4"
          src="/Assets/42i6idqc.png"
          alt="Logo"
        />

        {/* Menu Links */}
        <div className="flex flex-row justify-center items-center font-sans space-x-4 md:text-2xl mb-2">
          <Link 
            to="about" 
            smooth={true} 
            duration={500} 
            className="pl-4 cursor-pointer hover:underline text-white"
          >
            About Us
          </Link>
          <Link 
            to="trainers" 
            smooth={true} 
            duration={500} 
            className="cursor-pointer hover:underline text-white"
          >
            Trainers
          </Link>
          <Link 
            to="contact" 
            smooth={true} 
            duration={500} 
            className="cursor-pointer hover:underline text-white"
          >
            Contact Us
          </Link>
          <Link 
            to="signup" 
            smooth={true} 
            duration={500} 
            className="p-5 bg-white rounded-full text-black cursor-pointer hover:underline"
          >
            Sign Up
          </Link>
        </div>

        {/* 24/7 Image */}
        <img
          className="w-24 md:w-36 md:h-36 mt-4"
          src="Assets/247Logo.png"
          alt="24/7"
        />
      </div>
    </header>
  );
};

export default Header;
