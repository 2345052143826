import React from 'react';
import Slider from "react-slick";
import { useInView } from 'react-intersection-observer';
import './TrainerSlider.css'; // Import your CSS file for styles

const trainers = [
  {
    name: "Brian Strickland",
    image: "/Assets/BrianStrickland.jpg",
    review: "Brian is an incredible trainer. He helped me reach my goals quickly!"
  },
  {
    name: "CP London",
    image: "/Assets/CpLondon.jpg",
    review: "CP's training programs are well structured and effective"
  },
  {
    name: "Chanelle Neff",
    image: "/Assets/ChanelleNeff.jpg",
    review: "Chanelle is a motivational trainer who keeps pushing me to my limits."
  }
];

const TrainerSlider = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures the animation only happens once
    threshold: 0.1, // Trigger when 10% of the section is visible
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024, // For tablets and below
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className={`trainer-slider-section ${inView ? 'fade-in' : 'fade-out'}`} ref={ref}>

      <div className="container mx-auto py-10 cursor-pointer">
        <Slider {...settings}>
          {trainers.map((trainer, index) => (
            <div key={index} className="trainer-card">
              <div className="trainer-image">
                <img src={trainer.image} alt={trainer.name} />
              </div>
              <div className="trainer-info">
                <h3 className="trainer-name">{trainer.name}</h3>
                <p className="trainer-review">"{trainer.review}"</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TrainerSlider;
