import React from 'react';

const VideoComponent = () => {
  return (
    <div className="video-container w-full">
      <video autoPlay autoplay="autoplay" loop muted playsInline className="w-full h-auto">
        <source src="/Assets/dronefootage.mp4" type="video/mp4" />
        <source src="/Assets/dronefootage.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
