import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons"; // Corrected import
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css"; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Social Media Links */}
        <div className="social-media">
          <a href="https://www.instagram.com/southernstrengthfl/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61564817536514" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>

        {/* Contact Information */}
        <div className="footer-contact">
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon mr-2" />
            <a href="https://maps.google.com/?q=Southern+Strength+3111+US-98%2C+Lakeland%2C+FL+33803" target="_blank" rel="noopener noreferrer">
           3111 US-98, Lakeland, FL 33803
          </a>
          
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} className="icon mr-2" />
            <a href="tel:+18633239106">(863) 323-9106</a>
          </p>
        </div>

        {/* Copyright */}
        <div className="footer-copyright">
          <p>&copy; 2022 Southern Strength. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
