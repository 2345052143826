import React, { useState } from "react";
import './AboutUs.css'; // Import additional styles if needed

const images = [
  "/Assets/insideGym.jpg",
  "/Assets/Cardio Area.jpg",
  "/Assets/Front Desk.jpg",
  "/Assets/Front Entry.jpg",
  "/Assets/Southern Logo.jpg",
  "Assets/NutritionLogo.png"
];

const AboutUs = () => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <section className="about-us-section py-10">
      <div className="container mx-auto flex flex-col items-center justify-center">
        {/* About Us Text */}
        <div className="about-us-text-container md:w-full lg:w-1/2 p-6 text-center mb-10">
          <h2 className="about-us-heading text-4xl font-bold mb-4">About Us</h2>
          <p className="about-us-text text-lg">
          We’re a small, passionate team of experienced trainers and 
          athletes who know what it takes to succeed. 
          That’s why we’ve handpicked the best equipment in the area. We believe every workout 
          should have purpose and precision.
          </p>
        </div>

        {/* Enlarged Image */}
        <div className="about-us-image-container w-full md:w-4/5 lg:w-2/3 p-6 mb-8">
          <img 
            src={selectedImage} 
            alt="Selected" 
            className="about-us-image rounded-lg shadow-lg w-full h-auto lg:h-[500px] lg:max-h-[600px] object-cover"
          />
        </div>

        {/* Image Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index} className="cursor-pointer">
              <img
                src={image}
                alt={`Thumbnail ${index}`}
                className={`about-us-thumbnail rounded-lg shadow-lg w-full h-auto transition-transform transform hover:scale-105 ${selectedImage === image ? 'border-4 border-blue-500' : ''}`}
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
